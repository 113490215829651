import { Component, OnInit } from '@angular/core';

@Component({
  //selector: 'app-buttons',
  templateUrl: 'disclaimer.component.html',
  styleUrls: ['disclaimer.component.css']
})

export class DisclaimerPageComponent {
    
}