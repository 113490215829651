import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
//import { from  } from 'rxjs';
import { Register, User } from "../dashboard/register";

import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoginService {
  Url: string;
  token: string;
  header: any;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;


  constructor(private http: HttpClient) {
    this.Url = 'https://localhost:44321/api/Login/';
    const headerSettings: { [name: string]: string | string[]; } = {};
    this.header = new HttpHeaders(headerSettings);


    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  Login(model: any) {
    debugger;
    var a = this.Url + 'UserLogin';
    //return this.http.post<any>(this.Url + 'UserLogin', model, { headers: this.header });
    
    return this.http.get<any>(this.Url + 'UserLogin2', model);
  }

  
  CreateUser(register: Register) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<Register[]>(this.Url + 'createcontact/', register, httpOptions)
  }
}


