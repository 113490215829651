import { Component, OnInit } from '@angular/core';

@Component({
  //selector: 'app-buttons',
  templateUrl: 'faq.component.html',
  styleUrls: ['faq.component.css']
})

export class FaqPageComponent {
    
}