import { Component, OnInit } from '@angular/core';

@Component({
  //selector: 'app-buttons',
  templateUrl: 'home-page.component.html',
  styleUrls: ['home-page.component.css']
})

export class HomePageComponent {
    
}