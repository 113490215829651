import { Component, OnInit } from '@angular/core';

@Component({
  //selector: 'app-buttons',
  templateUrl: 'contact.component.html',
  styleUrls: ['contact.component.css']
})

export class ContactPageComponent {
    
}