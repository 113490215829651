import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-nav-header',
  templateUrl: 'page-header.component.html'
})

export class PageHeaderComponent {
  
  
  constructor(private router:Router) {
    
  }
  
    handleClick(link) {
      this.router.navigate([link]); 
      console.log('Click!', event)
    }
    
}