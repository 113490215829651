import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuard } from './_helpers/auth.guard';
import { LoginComponent } from './pages/login/login.component';
import { HomePageComponent } from './api/home-page/home-page.component';
import { LiveStationComponent } from './api/live-station/live-station.component';
import { AllApiListComponent } from './api/list/all-api.component';
import { SiteLoginComponent } from './login/login.component';
import { FaqPageComponent } from './faq/faq.component';
import { PricePageComponent } from './price/price.component';
import { ContactPageComponent } from './contact/contact.component';
import { AboutPageComponent } from './about/about.component';
import { DisclaimerPageComponent } from './disclaimer/disclaimer.component';

export const AppRoutes: Routes = [
    
    {
        path: '',
        component: HomePageComponent
    },
    {
        path: 'login',
        component: SiteLoginComponent
    },
    {
        path: 'disclaimer',
        component: DisclaimerPageComponent
    },
    {
        path: 'about',
        component: AboutPageComponent
    },
    {
        path: 'developers',
        component: AboutPageComponent
    },
    {
        path: 'forgot-password',
        component: AboutPageComponent
    },
    {
        path: 'contact-us',
        component: ContactPageComponent
    },
    {
        path: 'price',
        component: PricePageComponent
    },
    {
        path: 'frequently-asked-question',
        component: FaqPageComponent
    },
    {
        path: 'api-collection',
        component: AllApiListComponent
    },
    {
        path: 'api-collection/live-station',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/live-train-status',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/pnr-check',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/station-code-or-name',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/train-no-information',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/cancelled-trains-list',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/seat-availability-in-trains	',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/autocomplete-station',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/station-code-to-name',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/train-schedule',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/all-trains-on-station',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/partially-cancelled-trains-list',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/trains-between-station-via-station',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/coach-position',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/train-fare',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/station-name-to-code',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/train-number-to-name',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/rescheduled-trains-list',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/trains-between-station',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/coach-layout',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/train-name-to-number',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/diverted-trains-list',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/auto-complete-train-information',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/station-location-on-map',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/special-train',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/average-delay-of-rain',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/heritage-train',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/train-history',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/fog-affected-train',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/superfast-train',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/rajdhani-train',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/premium-train',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/shatabdi-train',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/garibrath-train',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/mail-express-train',
        component: LiveStationComponent
    },
    {
        path: 'api-collection/janshatabdi-train',
        component: LiveStationComponent
    },
    {
        path: '',
        redirectTo: 'app/dashboard',
        pathMatch: 'full',
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './dashboard/dashboard.module#DashboardModule'
            },
            {
                path: 'components', //canActivate: [AuthGuard],
                loadChildren: './components/components.module#ComponentsModule'
            },
            {
                path: 'forms',
                loadChildren: './forms/forms.module#Forms'
            }, {
                path: 'tables', //canActivate: [AuthGuard],
                loadChildren: './tables/tables.module#TablesModule'
            }, {
                path: 'maps',
                loadChildren: './maps/maps.module#MapsModule'
            }, {
                path: 'widgets',
                loadChildren: './widgets/widgets.module#WidgetsModule'
            }, {
                path: 'charts',
                loadChildren: './charts/charts.module#ChartsModule'
            }, {
                path: 'calendar',
                loadChildren: './calendar/calendar.module#CalendarModule'
            }, {
                path: '',
                loadChildren: './userpage/user.module#UserModule'
            }, {
                path: '',
                loadChildren: './timeline/timeline.module#TimelineModule'
            }
        ]
    }, 
    // {
    //     path: '',
    //     component: AuthLayoutComponent,
    //     children: [
    //         {
    //             path: 'api-collection',
    //             loadChildren: './api/api.module#ApiPagesModule'
    //         }
    //     ]
    // },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [{
            path: 'pages',
            loadChildren: './pages/pages.module#PagesModule'
        }]
    }
];
