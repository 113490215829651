import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';

import { LoginService } from './services/login.service';

// import { SocialLoginModule, AuthServiceConfig } from "angular5-social-login";
// import { GoogleLoginProvider, FacebookLoginProvider } from "angular5-social-login";
 
import { LoginOpt } from "angularx-social-login";
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";

 
const fbLoginOptions: LoginOpt = {
  scope: 'pages_messaging,pages_messaging_subscriptions,email,pages_show_list,manage_pages',
  return_scopes: true,
  enable_profile_selector: true
}; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11
 
const googleLoginOptions: LoginOpt = {
  scope: 'profile email'
}; // https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig
 
let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("105574014781-86fl60lc7vhc7oio1sj4pv1depin3nuo.apps.googleusercontent.com", googleLoginOptions)
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("Facebook-App-Id", fbLoginOptions)
  }
]);

export function provideConfig() {
  return config;
}
 

// export function getAuthServiceConfigs() {
//   let config = new AuthServiceConfig(
//       [
//         {
//           id: FacebookLoginProvider.PROVIDER_ID,
//           provider: new FacebookLoginProvider("Your-Facebook-app-id")
//         },
//         {
//           id: GoogleLoginProvider.PROVIDER_ID,
//           provider: new GoogleLoginProvider("105574014781-86fl60lc7vhc7oio1sj4pv1depin3nuo.apps.googleusercontent.com")
//         },
//       ]
//   );
//   return config;
// }
 


import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
} from '@angular/material';

import { AppComponent } from './app.component';

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

import { AppRoutes } from './app.routing';
import { AuthGuard } from './_helpers/auth.guard';
import { AuthenticationService } from './services/authentication.service';
import { HttpClient } from '@angular/common/http';
import { HomePageComponent } from './api/home-page/home-page.component';
import { LiveStationComponent } from './api/live-station/live-station.component';

import { PageHeaderComponent } from './api/header/page-header.component';
import { PageFooterComponent } from './api/footer/page-footer.component';
import { PageBackgroundComponent } from './api/page-background/page-background.component';
import { AllApiListComponent } from './api/list/all-api.component';
import { SiteLoginComponent } from './login/login.component';
import { AboutPageComponent } from './about/about.component';
import { FaqPageComponent } from './faq/faq.component';
import { DisclaimerPageComponent } from './disclaimer/disclaimer.component';
import { ContactPageComponent } from './contact/contact.component';
import { PricePageComponent } from './price/price.component';
import { ButtonsComponent } from './components/buttons/buttons.component';

//import { CarouselModule } from 'ngx-owl-carousel-o';
//import { CarouselHolderComponent } from './carousel/carousel-holder.component';

@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule
  ]
})
export class MaterialModule {}

@NgModule({
    imports:      [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        RouterModule.forRoot(AppRoutes),
        HttpModule,
        MaterialModule,
        MatNativeDateModule,
        SidebarModule,
        NavbarModule,
        FooterModule, SocialLoginModule, HttpClientModule//, CarouselModule 
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,HomePageComponent,
        AuthLayoutComponent,  AllApiListComponent,LiveStationComponent,
        PageHeaderComponent, PageFooterComponent, PageBackgroundComponent,//, CarouselHolderComponent 

        SiteLoginComponent, AboutPageComponent, FaqPageComponent, DisclaimerPageComponent,
        ContactPageComponent, PricePageComponent//, ButtonsComponent 
    ],
    
    providers: [AuthGuard,AuthenticationService, LoginService, HttpClient, 
      {
        provide: AuthServiceConfig, 
        useFactory: provideConfig //,  getAuthServiceConfigs
      }
    ],
    bootstrap:    [ AppComponent ]
})
export class AppModule { }
