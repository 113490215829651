import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  //selector: 'app-buttons',
  templateUrl: 'all-api.component.html',
  styleUrls: ['all-api.component.css']
})

export class AllApiListComponent {
    
  
  constructor(private router:Router) {
    
  }
  
    handleClick(link) {
      this.router.navigate(['/api-collection' + link]); 
      console.log('Click!', event)
    }
    
}